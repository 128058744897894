////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _, { isEmpty, isNull } from 'lodash'
import styled from 'styled-components'
///////COMPONENTS///////
import PageLoad from '../../components/PageLoad'
import DateFormatterInline from '../../components/Sematable/ColumnHelpers/DateFormatterInline'
import { apiGetDelivery } from '../../actions/delivery'
/////////ASSETS/////////
import CTCLogo300 from '../../assets/ctc-logo-300.png'

/////////STYLED/////////
const PSMain = styled.div`
  margin-left: -270px;
  margin-top: -57px;
  padding: 20px;
`
const PSHeader = styled.div`
  width: 100%;
  height: 80px;
  background-color: #aaa;
  border: 2px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PSHeaderRight = styled.div`
  height: 100%;
  background-color: #fff;
  width: 30%;
  border-left: 2px solid #000;
  text-align: center;
`
const PSTable = styled.div`
  display: flex;
  font-size: 0.8em;
`
const PSColumns = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
`
const PSColumn = styled.div`
  border: 2px solid #000;
`
const PSTitle = styled.h2`
  border-bottom: 2px solid #000;
  background-color: #ddd;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.3em;
`
const PSTD = styled.td`
  height: 75px !important;
`
const PSTH = styled.th`
  border-color: #000 !important;
  border-bottom: 1px solid #000;
`
const ColumnDetail = styled.div`
  display: flex;
  justify-content: space-between;
  height: 309px;
`
const ColumnKeys = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  width: 40%;
  text-align: center;
  font-weight: bold;
  border-right: 2px solid #000;
  background-color: #f5f5f5;
  padding-top: 1em;
`
const ColumnVariables = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 10px;
  width: 60%;
  text-align: center;
  padding-top: 1em;
`
const ColumnLine = styled.p`
  height: 3em;
  font-size: 0.8em;
`
/////////STYLED/////////

class PrintPackingList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  ///////////////////////////////// CONFIG ///////////////////////////////////////

  componentWillReceiveProps(nextProps) {
    this.setState({ delivery: nextProps.delivery })
  }

  componentDidMount() {
    this.props.apiGetDelivery(this.props.params.deliveryId)
  }

  ///////////////////////////////// RENDER ///////////////////////////////////////

  render() {
    const { trl, isLoading } = this.props
    const { delivery } = this.state
    console.log('DELIVERY',delivery)
    if (isLoading || _.isEmpty(delivery)) return <PageLoad text={trl.common__loading} size={80} thickness={9} />
    return (
      <PSMain>
        <PSHeader>
          <img style={{ marginLeft: '1em' }} src={CTCLogo300} alt="" />
          <h1 style={{ color: '#fff', textTransform: 'uppercase', fontWeight: 'bold' }}>
            {trl.printpackinglist_DocumentTitle ? trl.printpackinglist_DocumentTitle : 'Packing List'}
          </h1>
          <PSHeaderRight>
            <div style={{ fontWeight: 'Bold', height: '50%', display: 'flex', borderBottom: '1px solid #000' }}>
              <div
                style={{
                  width: '40%',
                  borderRight: '1px solid #000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span>
                  {trl.printpackinglist_Title_idDelivery ? trl.printpackinglist_Title_idDelivery : 'Packing List N°'}
                </span>
              </div>
              <div style={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ fontSize: '2em' }}>{delivery.idDeliveryJPI}</span>
              </div>
            </div>
            <div style={{ fontWeight: 'Bold', height: '50%', display: 'flex' }}>
              <div
                style={{
                  width: '40%',
                  borderRight: '1px solid #000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '.9em'
                }}
              >
                <span>{trl.printpackinglist_Title_Date ? trl.printpackinglist_Title_Date : 'Date'}</span>
              </div>
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '.9em'
                }}
              >
                <DateFormatterInline children={delivery.ctcDateConfirmed} />
              </div>
            </div>
          </PSHeaderRight>
        </PSHeader>
        <PSColumns style={{ marginBottom: '1em' }}>
          <PSColumn style={{ width: '35%', height: '342px' }}>
            <PSTitle>{trl.printpackinglist_headertitle_Sender}</PSTitle>
            <ColumnDetail>
              <ColumnKeys>
                <ColumnLine>{trl.printpackinglist_header_Company}</ColumnLine>
                <ColumnLine>{trl.printpackinglist_header_Address}</ColumnLine>
                <ColumnLine />
                <ColumnLine>{trl.printpackinglist_header_City}</ColumnLine>
                <ColumnLine>{trl.printpackinglist_header_Country}</ColumnLine>
                <ColumnLine>{trl.printpackinglist_header_Phone}</ColumnLine>
              </ColumnKeys>
              <ColumnVariables>
                <ColumnLine style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                  {delivery.sender.labelCompany}
                </ColumnLine>
                <ColumnLine>{`${delivery.sender.address.ligne_1}\n${delivery.sender.address.ligne_2}`}</ColumnLine>
                <ColumnLine>{`${delivery.sender.address.ligne_3}\n${delivery.sender.address.ligne_4}\n${
                  delivery.sender.address.ligne_5
                }`}</ColumnLine>
                <ColumnLine>{`${delivery.sender.address.postalCode} ${delivery.sender.address.city}`}</ColumnLine>
                <ColumnLine style={{ textTransform: 'uppercase' }}>{delivery.sender.address.country}</ColumnLine>
                <ColumnLine>{delivery.sender.address.phoneNumber}</ColumnLine>
              </ColumnVariables>
            </ColumnDetail>
          </PSColumn>
          <PSColumn style={{ width: '25%' }}>
            <PSTitle>{trl.printpackinglist_headertitle_Information}</PSTitle>
            <ColumnDetail>
              <ColumnKeys>
                <ColumnLine>{trl.printpackinglist_header_BL}</ColumnLine>
                <ColumnLine>{trl.printpackinglist_header_Incoterm}</ColumnLine>
                <ColumnLine>{trl.printpackinglist_header_DelMode}</ColumnLine>
              </ColumnKeys>
              <ColumnVariables>
                <ColumnLine>{delivery.idDeliverySAP}</ColumnLine>
                <ColumnLine>{delivery.incoterm}</ColumnLine>
                <ColumnLine>{`${delivery.packingList.carrierName.toLowerCase() == "other" ? "-" : delivery.packingList.carrierName}`}</ColumnLine>
              </ColumnVariables>
            </ColumnDetail>
          </PSColumn>
          <PSColumn style={{ width: '35%' }}>
            <PSTitle>{trl.printpackinglist_headertitle_Recipient}</PSTitle>
            <ColumnDetail>
              <ColumnKeys>
                <ColumnLine>{trl.prepsheet_header_Recipient}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Address}</ColumnLine>
                <ColumnLine />
                <ColumnLine>{trl.prepsheet_header_rec_City}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Country}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Phone}</ColumnLine>
                <ColumnLine>{trl.prepsheet_header_rec_Contact}</ColumnLine>
              </ColumnKeys>
              <ColumnVariables>
                <ColumnLine style={{ fontWeight: 'bold' }}>{delivery.recipient.labelAddress}</ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.ligne_1}\n${delivery.recipient.address.ligne_2}`}</ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.ligne_3}\n${delivery.recipient.address.ligne_4}\n${delivery.recipient.address.ligne_5}`}</ColumnLine>
                <ColumnLine>{`${delivery.recipient.address.postalCode} ${delivery.recipient.address.city}`}</ColumnLine>
                <ColumnLine style={{ textTransform: 'uppercase' }}>{delivery.recipient.address.country}</ColumnLine>
                <ColumnLine>{delivery.recipient.address.phoneNumber}</ColumnLine>
                <ColumnLine>{delivery.recipient.address.contact2}</ColumnLine>
              </ColumnVariables>
            </ColumnDetail>
          </PSColumn>
        </PSColumns>
        {delivery.packingList.containers.map((container, index) => {
          return (
            <PSTable className="saveandprint tableWrapperBorder" style={{ marginTop: '1em', borderWidth: '3px' }}>
              <table
                className="table table-sm table-bordered table-striped table-hover tableBox1"
                style={{ width: '25%', marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <PSTH style={{ backgroundColor: '#aaa', borderLeft: 0 }}>
                      {trl.printpackinglist_tableTitle_BoxID}
                    </PSTH>
                    <PSTH style={{ backgroundColor: '#aaa' }}>{trl.printpackinglist_tableTitle_Weight}</PSTH>
                    <PSTH style={{ backgroundColor: '#aaa' }}>{trl.printpackinglist_tableTitle_Dim}</PSTH>
                    <PSTH style={{ backgroundColor: '#aaa' }}>{trl.printpackinglist_tableTitle_Volume}</PSTH>
                  </tr>
                </thead>
                <tbody>
                  <tr rowSpan={`${container.selectedItems.length + 5}`}>
                    <PSTD>{container.label}</PSTD>
                    <PSTD>{container.weight}</PSTD>
                    <PSTD>{`${!isNull(container.width) ||!isEmpty(container.width) ? container.width : '0'}x${!isNull(container.lenght) ||!isEmpty(container.lenght) ? container.lenght : '0'}x${!isNull(container.depth) || !isEmpty(container.depth) ? container.depth : '0'}`}</PSTD>
                    <PSTD>{container.volume}</PSTD>
                  </tr>
                </tbody>
              </table>
              <table
                className="table table-sm table-bordered table-striped table-hover tableBox2"
                style={{ width: '50%', marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_SalesOrder}</PSTH>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_ClientPO}</PSTH>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_Brand}</PSTH>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_CTCItem}</PSTH>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_BrandItem}</PSTH>
                    <PSTH style={{ backgroundColor: '#ddd' }}>{trl.printpackinglist_tableTitle_Type}</PSTH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <PSTD />
                    <PSTD />
                    <PSTD />
                    <PSTD />
                    <PSTD />
                    <PSTD />
                  </tr>
                  {container.selectedItems.map(si => (
                    <tr>
                      <PSTD>{si.idSalesOrder_SAP}</PSTD>
                      <PSTD>{si.clientPo}</PSTD>
                      <PSTD>{si.brandName}</PSTD>
                      <PSTD>{si.ctcItem}</PSTD>
                      <PSTD>{si.brandItem}</PSTD>
                      <PSTD>{si.fixedVariable}</PSTD>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                className="table table-sm table-bordered table-striped table-hover tableBox3"
                style={{ width: '15%', marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <PSTH style={{ backgroundColor: '#f5f5f5' }}>{trl.printpackinglist_tableTitle_Model}</PSTH>
                    <PSTH style={{ backgroundColor: '#f5f5f5' }}>{trl.printpackinglist_tableTitle_Color}</PSTH>
                    <PSTH style={{ backgroundColor: '#f5f5f5' }}>{trl.printpackinglist_tableTitle_Size}</PSTH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <PSTD />
                    <PSTD />
                    <PSTD />
                  </tr>
                  {container.selectedItems.map(si => (
                    <tr>
                      <PSTD>{si.model}</PSTD>
                      <PSTD>{si.color}</PSTD>
                      <PSTD>{si.size}</PSTD>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table
                className="table table-sm table-bordered table-striped table-hover tableBox4"
                style={{ width: '10%', marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <PSTH style={{ borderRight: 0 }}>{trl.printpackinglist_tableTitle_Qty}</PSTH>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <PSTD />
                  </tr>
                  {container.selectedItems.map(si => (
                    <tr>
                      <PSTD>{si.selectedQuantity}</PSTD>
                    </tr>
                  ))}
                </tbody>
              </table>
            </PSTable>
          )
        })}
        <PSTable className="tableWrapperBorder saveandprint" style={{ borderWidth: '2px' }}>
          <table className="table table-sm table-bordered table-striped table-hover " style={{ marginBottom: 0 }}>
            <thead>
              <tr>
                <PSTH style={{ backgroundColor: '#ddd', borderLeft: 0 }} colSpan="4">
                  {trl.printpackinglist_tableTotal_TotalBox}
                </PSTH>
                <PSTH style={{ backgroundColor: '#ddd', borderRight: 0 }}>
                  {trl.printpackinglist_tableTotal_TotalQty}
                </PSTH>
              </tr>
            </thead>
            <tbody>
              <tr>
                <PSTD>{`${delivery.packingList.containers.length} ${
                  delivery.packingList.containers.length >= 2 ? 'boxes' : 'box'
                }`}</PSTD>
                <PSTD>{delivery.packingList.totalWeight} kg</PSTD>
                <PSTD />
                <PSTD>{delivery.packingList.totalVolume} cbm</PSTD>
                <PSTD>{delivery.packingList.totalItemQty}</PSTD>
              </tr>
            </tbody>
          </table>
        </PSTable>
        <div style={{ fontSize: '.8em', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <p>{trl.printpackinglist_BotomPage_TVA}</p>
            <p>{trl.printpackinglist_BotomPage_Capital}</p>
            <p>{trl.printpackinglist_BotomPage_Siret}</p>
          </div>
          <p style={{ textAlign: 'center' }}>{trl.printpackinglist_BotomPage_Address}</p>
          <p style={{ textAlign: 'center' }}>{trl.printpackinglist_BotomPage_Contact}</p>
        </div>
      </PSMain>
    )
  }
}

PrintPackingList.propTypes = {
  delivery: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiGetDelivery: PropTypes.func.isRequired
}

PrintPackingList.defaultProps = {
  delivery: {}
}

const mapStateToProps = state => ({
  delivery: state.delivery.delivery,
  isLoading: state.delivery.isLoading
})

export default connect(
  mapStateToProps,
  { apiGetDelivery }
)(PrintPackingList)
